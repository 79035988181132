<template>
  <v-col cols="10" sm="10" md="10" lg="8" xl="8" class="mt-4 mb-12">
    <p class="text-h6 text-sm-h5 text-md-h5 primary--text text-center">
      {{
        isTherapy
          ? $t('therapist.account.manage')
          : isParent
          ? $t('parent.account.manage')
          : $t('admin.account.manage')
      }}
    </p>
    <v-card class="teacher__card mb-12 elevation-0">
      <v-data-table
        :headers="headers"
        :items="filteredUsers"
        sort-by="lastname"
        :search="search"
        id="teacher-table"
        class="teacher__data-table grey--text text--darken-3"
        :locale="'de-GE'"
        hide-default-footer
        disable-pagination
        :footer-props="{
          itemsPerPageText: 'Zeilen pro Seite',
          pageText: '{0}-{1} von {2}',
          itemsPerPageAllText: 'Alle',
        }"
      >
        <template v-slot:top>
          <EmailDialog
            :dialog="dialogEmail"
            :user="editedItem"
            :loading="loadEmail"
            :isTherapist="isTherapy"
            @on-close-email="close"
            @on-send-email="sendEmail"
          />
          <EditDialogTherapyAdmin
            v-if="isTherapyAdmin"
            :show="dialogEdit"
            :user="editedItem"
            :classes="classes"
            :loading="loadEdit"
            :title="$t('therapist.edit-therapist')"
            @on-close="close"
            @on-save="save"
          />
          <EditDialog
            v-else
            :show="dialogEdit"
            :user="editedItem"
            :isAdmin="isAdmin"
            :loading="loadEdit"
            @on-close="close"
            @on-save="save"
          />

          <DeleteDialog
            :dialogDelete="dialogDelete"
            :user="editedItem"
            @deleted-user="editedTeacher"
            @on-close-delete="close"
          />
        </template>
        <template v-slot:[`item.classes`]="{ item }">
          <p v-if="item.classes?.length === 0" class="error--text ma-0">
            {{ $t('adminTable.noClass') }}
          </p>
          <template v-else>
            <v-row class="ma-0 justify-center">
              <v-chip v-for="c in item.classes" :key="c.id + '_' + item._id">
                {{ c.name }}
              </v-chip>
            </v-row>
          </template>
        </template>
        <template v-slot:[`item.firstLogin`]="{ item }">
          <v-icon v-if="item.firstLogin === 'true'" color="#b284fe">
            mdi-close-circle
          </v-icon>
          <v-icon v-else color="#06dfb4"> mdi-check-circle </v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-col cols="12" class="user-table__action-item pa-0">
            <v-btn text @click="openEmail(item)" color="primary" class="pl-0">
              <v-icon class="mr-2" color="primary">
                {{
                  item.firstLogin ? 'mdi-email-fast' : 'mdi-lock-reset'
                }}</v-icon
              >
              <p v-if="item.firstLogin" class="text-center ma-0 mt-1 text-none">
                {{ $t('admin.send-registration') }}
              </p>
              <p v-else class="text-center ma-0 mt-1 text-none">
                {{ $t('admin.reset-password') }}
              </p>
            </v-btn>
          </v-col>
          <v-col cols="12" class="user-table__action-item pa-0 py-1">
            <v-btn class="pl-0" text @click="editItem(item)" color="primary">
              <v-icon class="mr-2" color="primary"> mdi-pencil </v-icon>
              <p class="text-center ma-0 mt-1 text-none">{{ $t('edit') }}</p>
            </v-btn>
          </v-col>
          <v-col
            v-if="isSchool"
            cols="12"
            class="user-table__action-item pa-0 py-1"
          >
            <PromoteToAdminButton :user-id="item._id" />
          </v-col>
          <v-col cols="12" class="user-table__action-item pa-0 py-1">
            <v-btn
              text
              @click="deleteItem(item)"
              color="primary"
              :disabled="cannotDelete(item._id)"
              class="pl-0"
            >
              <v-icon color="primary" class="pr-2"> mdi-delete</v-icon>
              <p class="text-center ma-0 mt-1 text-none">
                {{ isSchool ? $t('delete-from-school') : $t('delete') }}
              </p>
            </v-btn>
          </v-col>
        </template>
      </v-data-table>
    </v-card>
  </v-col>
</template>

<script lang="ts">
import PromoteToAdminButton from './buttons/PromoteToAdminButton.vue';
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import {
  Client,
  GetSchoolClassDto,
  UpdateUserDto,
  UserDto,
} from '../../../../api/types';
import SnackbarModule from '../../../../store/modules/SnackbarModule';
import AdminSnackbar from '../../base/AdminSnackbar.vue';
import DeleteDialog from './DeleteDialog.vue';
import EditDialog from './EditDialog.vue';
import EditDialogTherapyAdmin from './EditDialogTherapyAdmin.vue';
import EmailDialog from './EmailDialog.vue';

@Component({
  components: {
    EditDialog,
    AdminSnackbar,
    DeleteDialog,
    EmailDialog,
    EditDialogTherapyAdmin,
    PromoteToAdminButton,
  },
})
export default class TeachersTable extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  @Prop() users!: UserDto[];
  @Prop() classes!: GetSchoolClassDto[];
  @Prop() isTherapyAdmin!: boolean;
  @Prop({ default: false }) isAdmin?: boolean;

  search = '';
  loadEdit = false;
  loadDelete = false;
  loadEmail = false;
  dialogEdit = false;
  dialogDelete = false;
  dialogEmail = false;
  editedItem: UserDto | null = null;

  headers = this.isTherapyAdmin
    ? [
        { text: 'Vorname', value: 'firstname' },
        { text: 'Nachname', value: 'lastname' },
        { text: 'Email', value: 'email' },
        {
          text: 'Aktiviert',
          value: 'firstLogin',
          align: 'center',
          sortable: false,
        },
        { text: 'Aktionen', value: 'actions', sortable: false },
      ]
    : [
        { text: 'Klasse', value: 'classes', align: 'center' },
        { text: 'Vorname', value: 'firstname' },
        { text: 'Nachname', value: 'lastname' },
        { text: 'Email', value: 'email' },
        {
          text: 'Aktiviert',
          value: 'firstLogin',
          align: 'center',
          sortable: false,
        },
        { text: 'Aktionen', value: 'actions', sortable: false },
      ];

  get filteredUsers(): UserDto[] {
    const adminIds = this.schoolModule.adminIds;
    return this.users.filter((user) => !adminIds.includes(user._id));
  }

  get isTherapy(): boolean {
    return this.schoolModule.clientType === Client.therapy;
  }

  get isParent(): boolean {
    return this.schoolModule.clientType === Client.parent;
  }

  get isSchool(): boolean {
    return this.schoolModule.isSchoolClient;
  }

  cannotDelete(id: string): boolean {
    return this.userModule.currentUser._id === id;
  }

  editItem(user: UserDto): void {
    this.editedItem = user;
    this.dialogEdit = true;
  }

  deleteItem(user: UserDto): void {
    this.editedItem = user;
    this.dialogDelete = true;
  }

  openEmail(user: UserDto): void {
    this.editedItem = user;
    this.dialogEmail = true;
  }

  close(): void {
    this.editedItem = null;
    this.dialogEmail = false;
    this.dialogEdit = false;
    this.dialogDelete = false;
  }

  async save(user: UpdateUserDto): Promise<void> {
    try {
      this.loadEdit = true;
      await this.schoolModule.updateUser({
        id: this.editedItem!._id,
        updateUser: user,
      });

      if (this.isSchool && user.classes) {
        await this.schoolModule.updateClassesOfTeacher({
          schoolId: this.schoolModule.schoolId ?? '',
          teacherId: this.editedItem!._id,
          teacherClasses: user.classes,
        });
      }

      this.snackbarModule.showMessage({
        message: this.isParent
          ? this.$i18n.t('snackbar.parents.edited.success').toString()
          : this.isTherapyAdmin
          ? this.$i18n.t('snackbar.therapists.edited.success').toString()
          : this.$i18n.t('snackbar.teachers.edited.success').toString(),
        isSuccess: true,
      });
      this.editedTeacher();
    } catch (error: any) {
      if (error.response?.status == 409) {
        this.snackbarModule.showMessage({
          message: this.isParent
            ? this.$i18n.t('snackbar.parents.edited.errorMail').toString()
            : this.isTherapyAdmin
            ? this.$i18n.t('snackbar.therapists.edited.errorMail').toString()
            : this.$i18n.t('snackbar.teachers.edited.errorMail').toString(),
          isSuccess: false,
        });
      } else {
        this.snackbarModule.showMessage({
          message: this.isTherapyAdmin
            ? this.$i18n.t('snackbar.therapists.edited.error').toString()
            : this.isParent
            ? this.$i18n.t('snackbar.teachers.edited.error').toString()
            : this.$i18n.t('snackbar.teachers.edited.error').toString(),
          isSuccess: false,
        });
      }
    } finally {
      this.loadEdit = false;
      this.close();
    }
  }

  async sendEmail(user: UserDto): Promise<void> {
    this.loadEmail = true;
    try {
      if (!user.email) throw new Error('Keine Email-Adresse vorhanden');
      await this.schoolModule.sendPasswordMail(user._id);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.email.success').toString(),
        isSuccess: true,
      });
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.email.error').toString(),
        isSuccess: false,
      });
    }
    this.dialogEmail = false;
    this.loadEmail = false;
  }

  @Emit()
  editedTeacher(): void {
    console.log();
  }
}
</script>

<style lang="scss" scoped>
.teacher {
  &__card {
    .v-data-table::v-deep th {
      font-size: 16px !important;
      font-weight: 600;
    }
    tbody tr:nth-of-type(even) {
      background-color: $box-bg !important;
    }

    &__action-item {
      height: 25% !important;
    }
  }

  &__data-table {
    line-height: 2;
    box-shadow: none !important;
  }
}
</style>

<style lang="scss">
tbody tr:nth-of-type(even) {
  background-color: $box-bg !important;
}
</style>
