<template>
  <v-col cols="10" sm="10" md="10" lg="8" xl="8" class="mt-4 mb-12">
    <p class="text-h6 text-sm-h5 text-md-h5 primary--text text-center">
      {{ $t('admin.account.manage-admin') }}
    </p>
    <v-card class="admin-table mb-12 elevation-0">
      <v-data-table
        id="admin-data-table"
        :headers="headers"
        :items="filteredUsers"
        sort-by="grade"
        :search="search"
        :locale="'de-GE'"
        hide-default-footer
        disable-pagination
        :footer-props="{
          itemsPerPageText: 'Zeilen pro Seite',
          pageText: '{0}-{1} von {2}',
          itemsPerPageAllText: 'Alle',
        }"
        class="grey--text text--darken-3"
      >
        <template v-slot:top>
          <EmailDialog
            :dialog="dialogEmail"
            :user="editedItem"
            :loading="loadEmail"
            @on-close-email="close"
            @on-send-email="sendEmail"
          />
          <EditDialogTherapyAdmin
            v-if="isTherapyAdmin"
            :show="dialogEdit"
            :user="editedItem"
            :classes="classes"
            :loading="loadEdit"
            :title="$t('therapist.edit-admin')"
            @on-close="close"
            @on-save="save"
          />
          <EditDialog
            v-else
            :show="dialogEdit"
            :user="editedItem"
            :isAdmin="isAdmin"
            :loading="loadEdit"
            @on-close="close"
            @on-save="save"
          />
        </template>
        <template v-slot:[`item.classes`]="{ item }">
          <p v-if="item.classes?.length === 0" class="error--text ma-0">
            {{ $t('adminTable.noClass') }}
          </p>
          <template v-else>
            <v-row class="ma-0 justify-center">
              <v-chip v-for="c in item.classes" :key="c.id + '_' + item._id">
                {{ c.name }}
              </v-chip>
            </v-row>
          </template>
        </template>
        <template v-slot:[`item.firstLogin`]="{ item }">
          <v-icon
            class="ma-0 pa-0"
            v-if="item.firstLogin === 'true'"
            color="#b284fe"
          >
            mdi-close-circle
          </v-icon>
          <v-icon class="ma-0 pa-0" v-else color="#06dfb4">
            mdi-check-circle
          </v-icon>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-col cols="12" class="user-table__action-item pa-0 py-1">
            <v-btn class="pl-0" text @click="editItem(item)" color="primary">
              <v-icon class="mr-2" color="primary"> mdi-pencil </v-icon>
              <p class="text-center ma-0 mt-1 text-none">{{ $t('edit') }}</p>
            </v-btn>
          </v-col>
          <v-col
            v-if="isSchoolClient && !isMe(item._id)"
            cols="12"
            class="user-table__action-item pa-0 py-1"
          >
            <PromoteToAdminButton :user-id="item._id" :is-promote="false" />
          </v-col>
        </template>
      </v-data-table>
    </v-card>
  </v-col>
</template>

<script lang="ts">
import PromoteToAdminButton from './buttons/PromoteToAdminButton.vue';
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { UpdateUserDto, UserDto } from '../../../../api/types';
import SnackbarModule from '../../../../store/modules/SnackbarModule';
import AdminSnackbar from '../../base/AdminSnackbar.vue';
import EditDialog from './EditDialog.vue';
import EditDialogTherapyAdmin from './EditDialogTherapyAdmin.vue';
import EmailDialog from './EmailDialog.vue';

@Component({
  components: {
    EditDialog,
    AdminSnackbar,
    EmailDialog,
    EditDialogTherapyAdmin,
    PromoteToAdminButton,
  },
})
export default class AdminTable extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  @Prop() users!: UserDto[];
  @Prop() classes!: string[];
  @Prop() isTherapyAdmin!: boolean;
  @Prop({ default: false }) isAdmin?: boolean;

  search = '';
  loadEdit = false;
  loadDelete = false;
  loadEmail = false;
  dialogEdit = false;
  dialogEmail = false;
  editedItem: UserDto | null = null;

  headers = this.isTherapyAdmin
    ? [
        { text: 'Vorname', value: 'firstname' },
        { text: 'Nachname', value: 'lastname' },
        { text: 'Email', value: 'email' },
        {
          text: 'Aktiviert',
          value: 'firstLogin',
          align: 'center',
          sortable: false,
        },
        { text: 'Aktionen', value: 'actions', sortable: false },
      ]
    : [
        { text: 'Klasse', value: 'classes', align: 'center' },
        { text: 'Vorname', value: 'firstname' },
        { text: 'Nachname', value: 'lastname' },
        { text: 'Email', value: 'email' },
        {
          text: 'Aktiviert',
          value: 'firstLogin',
          align: 'center',
          sortable: false,
        },
        { text: 'Aktionen', value: 'actions', sortable: false },
      ];

  admins: UserDto[] = [];

  get filteredUsers(): UserDto[] {
    const adminIds = this.schoolModule.adminIds;
    return this.users.filter((user) => adminIds.includes(user._id));
  }

  isMe(id: string): boolean {
    return this.userModule.currentUser._id === id;
  }

  editItem(user: UserDto): void {
    this.editedItem = user;
    this.dialogEdit = true;
  }

  openEmail(user: UserDto): void {
    this.editedItem = user;
    this.dialogEmail = true;
  }

  get isSchoolClient(): boolean {
    return this.schoolModule.isSchoolClient;
  }

  close(): void {
    this.editedItem = null;
    this.dialogEmail = false;
    this.dialogEdit = false;
  }

  async save(user: UpdateUserDto): Promise<void> {
    try {
      this.loadEdit = true;
      await this.schoolModule.updateUser({
        id: this.editedItem!._id,
        updateUser: user,
      });

      if (this.isSchoolClient && user.classes) {
        await this.schoolModule.updateClassesOfTeacher({
          schoolId: this.schoolModule.schoolId ?? '',
          teacherId: this.editedItem!._id,
          teacherClasses: user.classes,
        });

        // INFO refetch me if admin updated himself
        if (this.editedItem!._id === this.userModule.currentUser._id) {
          await this.userModule.whoAmI(this.schoolModule.schoolId ?? '');
        }
      }

      this.snackbarModule.showMessage({
        message: this.isTherapyAdmin
          ? this.$i18n.t('snackbar.therapists.edited.success').toString()
          : this.$i18n.t('snackbar.teachers.edited.success').toString(),
        isSuccess: true,
      });
      this.editedTeacher();
    } catch (error: any) {
      if (error.response?.status == 409) {
        this.snackbarModule.showMessage({
          message: this.isTherapyAdmin
            ? this.$i18n.t('snackbar.therapists.edited.errorMail').toString()
            : this.$i18n.t('snackbar.teachers.edited.errorMail').toString(),
          isSuccess: false,
        });
      } else {
        this.snackbarModule.showMessage({
          message: this.isTherapyAdmin
            ? this.$i18n.t('snackbar.therapists.edited.error').toString()
            : this.$i18n.t('snackbar.teachers.edited.error').toString(),
          isSuccess: false,
        });
      }
    } finally {
      this.loadEdit = false;
      this.close();
    }

    // INFO refetch my info as admin
    try {
      await this.userModule.refetchMe(this.userModule.currentUser._id);
    } catch (error) {
      console.log(error);
    }
  }

  async sendEmail(user: UserDto): Promise<void> {
    this.loadEmail = true;
    try {
      if (!user.email) throw new Error('Keine Email-Adresse vorhanden');
      await this.schoolModule.sendPasswordMail(user._id);
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.email.success').toString(),
        isSuccess: true,
      });
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('snackbar.email.error').toString(),
        isSuccess: false,
      });
    }
    this.dialogEmail = false;
    this.loadEmail = false;
  }

  @Emit()
  editedTeacher(): void {
    console.log();
  }
}
</script>

<style lang="scss" scoped>
.admin-table {
  .v-data-table {
    line-height: 2;
    box-shadow: none !important;

    &__wrapper tr:nth-of-type(even) {
      background-color: $row-color;
    }
  }

  .v-data-table::v-deep th {
    font-size: 16px !important;
    font-weight: 600;
  }

  &__action-item {
    height: 25% !important;
  }
}
</style>
