<template>
  <v-btn
    class="pl-0"
    color="primary"
    text
    :loading="loading"
    @click="promoteOrDemoteAdmin"
  >
    <v-icon v-if="isPromote" class="mr-2">mdi-account-arrow-up</v-icon>
    <v-icon v-else class="mr-2">mdi-account-arrow-down</v-icon>
    <p class="ma-0 mt-1 text-none">
      {{ isPromote ? $t('adminTable.promote') : $t('adminTable.demote') }}
    </p>
  </v-btn>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import { Prop } from 'vue-property-decorator';
import ClientModule from '@/store/modules/ClientModule';
import SchoolModule from '@/store/modules/SchoolModule';
import SnackbarModule from '@/store/modules/SnackbarModule';

@Component
export default class PromoteToAdminButton extends Vue {
  @Prop({ default: true })
  isPromote?: boolean;

  @Prop()
  userId!: string;

  clientModule: ClientModule = getModule(ClientModule, this.$store);
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  loading = false;

  get schoolId(): string {
    return this.schoolModule.schoolId ?? '';
  }

  get slug(): string {
    return this.schoolModule.school?.slug ?? '';
  }

  async promoteOrDemoteAdmin() {
    try {
      this.loading = true;
      await this.clientModule.addOrRemoveAsAdmin({
        schoolId: this.schoolId,
        adminDto: { keycloakId: this.userId },
      });
      await this.schoolModule.getBasicSchoolInfo(this.slug);
      this.snackbarModule.showMessage({
        message: this.isPromote
          ? this.$i18n.t('adminTable.snackbar.promote.success').toString()
          : this.$i18n.t('adminTable.snackbar.demote.success').toString(),
        isSuccess: true,
      });
    } catch (error) {
      console.error(error);
      this.snackbarModule.showMessage({
        message: this.isPromote
          ? this.$i18n.t('adminTable.snackbar.promote.error').toString()
          : this.$i18n.t('adminTable.snackbar.demote.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.loading = false;
    }
  }
}
</script>

<style scoped lang="scss"></style>
