<template>
  <v-dialog v-model="show" max-width="600px" persistent>
    <v-card>
      <v-card-title class="mt-2 justify-center">
        <p class="text-h5 font-weight-bold">
          {{ title }}
        </p>
      </v-card-title>
      <v-form @submit.prevent="save">
        <v-card-text>
          <v-container class="edit-therapist-form">
            <v-col cols="12">
              <v-text-field
                v-model="firstname"
                label="Vorname"
                id="firstname"
                data-testid="edit-therapist-firstname"
                :error-messages="firstnameErrors"
                @input="$v.firstname.$touch()"
                @blur="$v.firstname.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="lastname"
                label="Nachname"
                id="lastname"
                data-testid="edit-therapist-lastname"
                :error-messages="lastnameErrors"
                @input="$v.lastname.$touch()"
                @blur="$v.lastname.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="email"
                label="Email"
                id="email"
                data-testid="edit-therapist-email"
                :error-messages="emailErrors"
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
              ></v-text-field>
            </v-col>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onClose">
            <p class="ma-0 mt-1 pa-0">{{ $t('cancel') }}</p>
          </v-btn>
          <v-btn
            color="green"
            dark
            :loading="loading"
            type="submit"
            id="edit-therapist"
          >
            <p class="ma-0 mt-1 pa-0">{{ $t('save') }}</p>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { email, minLength, required } from 'vuelidate/lib/validators';
import { UpdateUserDto, UserDto } from '../../../../api/types/api';

@Component({
  mixins: [validationMixin],
  validations: {
    firstname: { required, minLength: minLength(3) },
    lastname: { required, minLength: minLength(3) },
    email: { required, email },
  },
})
export default class EditDialogTherapyAdmin extends Vue {
  @Prop() show!: boolean;
  @Prop() user!: UserDto;
  @Prop() classes?: string[];
  @Prop() loading!: boolean;
  @Prop() title!: string;

  firstname = '';
  lastname = '';
  email = '';

  @Watch('show')
  onShowChange() {
    if (this.show) {
      this.$v.$reset();
      this.firstname = this.user.firstname;
      this.lastname = this.user.lastname;
      this.email = this.user.email ?? '';
    }
  }

  get userTerm(): string {
    return 'Therapeut/in';
  }

  save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      const user: UpdateUserDto = {
        grade: '',
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
      };
      this.onSave(user);
    }
  }

  @Emit()
  onSave(user: UpdateUserDto) {
    return user;
  }

  @Emit()
  onClose(): void {
    console.log();
  }

  get firstnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.firstname.$dirty) return errors;
    !this.$v.firstname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.firstname.required && errors.push('Bitte Vornamen angeben.');
    return errors;
  }
  get lastnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.lastname.$dirty) return errors;
    !this.$v.lastname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.lastname.required && errors.push('Bitte Nachnamen angeben.');
    return errors;
  }

  get emailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.email.$dirty) return errors;
    !this.$v.email.email && errors.push('Keine gültige E-Mailadresse.');
    !this.$v.email.required && errors.push('Bitte E-Mail angeben.');
    return errors;
  }
}
</script>

<style scoped lang="scss"></style>
