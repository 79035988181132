<template>
  <v-col
    cols="12"
    class="add-teacher-row d-flex justify-center align-self-start"
  >
    <v-col cols="10" md="10" lg="8">
      <v-form @submit.prevent="validate">
        <v-container class="justify-center">
          <v-row class="justify-center">
            <v-col cols="10" sm="10" md="4" lg="4" xl="4">
              <v-text-field
                v-model="firstname"
                :error-messages="firstnameErrors"
                label="Vorname*"
                id="firstname"
                outlined
                dense
                required
                background-color="white"
              ></v-text-field>
            </v-col>

            <v-col cols="10" sm="10" md="4" lg="4" xl="4">
              <v-text-field
                v-model="lastname"
                :error-messages="lastnameErrors"
                label="Nachname*"
                id="lastname"
                outlined
                dense
                required
                background-color="white"
              ></v-text-field>
            </v-col>

            <v-col cols="10" sm="10" md="4" lg="4" xl="4">
              <v-text-field
                v-model="email"
                :error-messages="emailErrors"
                label="E-mail*"
                id="email"
                outlined
                dense
                required
                background-color="white"
              ></v-text-field>
            </v-col>
            <v-btn
              id="create-therapist"
              :loading="loading"
              color="primary"
              class="mr-4"
              type="submit"
            >
              <v-icon left dark>mdi-account-plus </v-icon>
              Zugang erstellen
            </v-btn>
          </v-row>
        </v-container>
      </v-form>
      <v-col cols="10" class="mb-6"> </v-col>
    </v-col>
  </v-col>
</template>

<script lang="ts">
import { Client, Role } from '@/api/types';
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { email, minLength, required } from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';
import { DEFAULT_PASSWORD } from '../../../utils/common';
import SnackbarModule from '@/store/modules/SnackbarModule';

@Component({
  mixins: [validationMixin],
  validations: {
    firstname: { required, minLength: minLength(3) },
    lastname: { required, minLength: minLength(3) },
    email: { required, email },
  },
  components: {},
})
export default class TeacherDashboardTherapistAddAccount extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  @Prop() items!: string[];

  firstname = '';
  lastname = '';
  cEmail = '';

  userTerm = 'Therapeut/in';

  snackbar = false;
  isSuccess = false;
  message = '';
  loading = false;

  get email(): string {
    return this.cEmail.trim();
  }

  set email(value: string) {
    this.cEmail = value;
  }

  get isParent(): boolean {
    return this.schoolModule.clientType === Client.parent;
  }

  get schoolId(): string {
    return this.schoolModule.schoolId!.toString();
  }

  async validate(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.loading = true;
      try {
        await this.schoolModule.addUser({
          username: this.email,
          firstname: this.firstname,
          lastname: this.lastname,
          grade: '',
          email: this.email,
          schoolId: this.schoolId.toString(),
          role: this.isParent ? Role.parent : Role.therapist,
          password: DEFAULT_PASSWORD,
        });
        this.snackbarModule.showMessage({
          message: !this.isParent
            ? this.$i18n.t('snackbar.therapists.created.success').toString()
            : this.$i18n.t('snackbar.parents.created.success').toString(),
          isSuccess: true,
        });
        this.$v.$reset();
        this.clear();
        this.$emit('addedTeacher');
      } catch (error: any) {
        if (error?.response.status === 409) {
          this.snackbarModule.showMessage({
            message: !this.isParent
              ? this.$i18n.t('snackbar.therapists.created.errorMail').toString()
              : this.$i18n.t('snackbar.parents.created.errorMail').toString(),
            isSuccess: false,
          });
        } else {
          this.snackbarModule.showMessage({
            message: !this.isParent
              ? this.$i18n.t('snackbar.therapists.created.error').toString()
              : this.$i18n.t('snackbar.parents.created.error').toString(),
            isSuccess: false,
          });
        }
      } finally {
        this.loading = false;
      }
    }
  }

  clear(): void {
    this.$v.$reset();
    this.firstname = '';
    this.lastname = '';
    this.email = '';
  }

  get firstnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.firstname.$dirty) return errors;
    !this.$v.firstname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.firstname.required && errors.push('Bitte Vornamen angeben.');
    return errors;
  }
  get lastnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.lastname.$dirty) return errors;
    !this.$v.lastname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.lastname.required && errors.push('Bitte Nachnamen angeben.');
    return errors;
  }

  get emailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.email.$dirty) return errors;
    !this.$v.email.email && errors.push('Keine gültige E-Mailadresse.');
    !this.$v.email.required && errors.push('Bitte E-Mail angeben.');
    return errors;
  }

  showSnackbar(message: string, isSuccess: boolean) {
    this.snackbar = true;
    this.isSuccess = isSuccess;
    this.message = message;
    setTimeout(async () => {
      this.snackbar = false;
    }, 2500);
  }
}
</script>

<style scoped lang="scss"></style>
