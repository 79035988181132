<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <v-card>
      <v-card-title class="justify-center">
        <p class="text-h5 font-weight-bold">{{ $t('send-mail') }}</p>
      </v-card-title>
      <v-card-text>
        <v-row v-if="user?.firstLogin" class="d-flex justify-center my-3">
          <p class="body-1 text-center">
            Möchten Sie an
            <br /><span class="text-h6 font-weight-bold">
              {{ user.firstname }} {{ user.lastname }} </span
            ><br />eine E-Mail-Einladung schicken?
          </p>
          <p class="body-1 text-center mx-3">
            {{ $t(`welcomeMail.${title}.info`) }}
          </p>
        </v-row>
        <v-row v-else class="d-flex justify-center my-3">
          <p class="body-1 text-center">
            Möchten Sie wirklich das Passwort von
            <br />
            <span class="text-h6 font-weight-bold">
              {{ user?.firstname }} {{ user?.lastname }}
            </span>
            <br />
            zurücksetzen?
          </p>
          <p class="body-1 text-center mx-3">
            {{ $t(`welcomeMail.${title}.resetInfo`) }}
          </p>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onCloseEmail">
          <p class="ma-0 mt-1 pa-0">{{ $t('cancel') }}</p>
        </v-btn>
        <v-btn
          id="send-welcome-email-button"
          color="green"
          dark
          :loading="loading"
          @click="onSendEmail"
        >
          <p class="ma-0 mt-1 pa-0">{{ $t('send') }}</p>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { UserDto } from '../../../../api/types/api';

@Component
export default class EmailDialog extends Vue {
  @Prop() dialog!: boolean;
  @Prop() user!: UserDto;
  @Prop() loading!: boolean;
  @Prop() isTherapist!: boolean;

  get title(): string {
    return this.isTherapist ? 'therapist' : 'teacher';
  }

  @Emit()
  onSendEmail() {
    return this.user;
  }

  @Emit()
  onCloseEmail(): void {
    console.log();
  }
}
</script>

<style scoped lang="scss"></style>
