<template>
  <v-dialog v-model="dialogDelete" max-width="500px" persistent>
    <v-card>
      <v-card-title class="justify-center">
        <p class="text-h5 font-weight-bold">Achtung!</p>
      </v-card-title>
      <v-card-text>
        <v-row class="d-flex flex-column justify-center align-center my-3">
          <p class="body-1">
            Möchten Sie
            <span class="text-h6 font-weight-bold">
              {{ user?.firstname }} {{ user?.lastname }} </span
            >wirklich löschen?
          </p>

          <p class="body-1">
            Diese Aktion kann nicht rückgangig gemacht werden.
          </p>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onCloseDelete">
          <p class="ma-0 mt-1 pa-0">{{ $t('cancel') }}</p>
        </v-btn>
        <v-btn
          id="delete-user"
          color="error"
          dark
          @click="deleteUser"
          :loading="loadDelete"
        >
          <p class="ma-0 mt-1 pa-0">{{ $t('delete') }}</p></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import { UserDto } from '../../../../api/types/api';
import SchoolModule from '@/store/modules/SchoolModule';
import { getModule } from 'vuex-module-decorators';
import SnackbarModule from '@/store/modules/SnackbarModule';

@Component
export default class DeleteDialog extends Vue {
  @Prop() dialogDelete!: boolean;
  @Prop() user!: UserDto;
  @Prop() loading!: boolean;

  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  loadDelete = false;

  @Emit()
  onCloseDelete(): void {
    console.log();
  }

  @Emit()
  deletedUser(): void {
    console.log();
  }

  get isTherapyClient(): boolean {
    return this.schoolModule.isTherapyClient;
  }

  get schoolId(): string {
    return this.schoolModule.schoolId ?? '';
  }

  async deleteUser(): Promise<void> {
    try {
      this.loadDelete = true;
      if (this.isTherapyClient) {
        await this.schoolModule.deleteAllPatientsOfTherapist({
          schoolId: this.schoolId,
          grade: this.user._id,
        });
      }
      await this.schoolModule.deleteUser(this.user._id);
      this.snackbarModule.showMessage({
        message: this.isTherapyClient
          ? this.$i18n.t('snackbar.therapists.delete.success').toString()
          : this.$i18n.t('snackbar.teachers.delete.success').toString(),
        isSuccess: true,
      });
      // INFO to fetch teachers again
      this.deletedUser();
      this.onCloseDelete();
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.isTherapyClient
          ? this.$i18n.t('snackbar.therapists.delete.error').toString()
          : this.$i18n.t('snackbar.teachers.delete.error').toString(),
        isSuccess: false,
      });
    } finally {
      this.loadDelete = false;
    }
  }
}
</script>

<style scoped lang="scss"></style>
