<template>
  <v-col
    cols="12"
    class="teacher-dashboard d-flex flex-column justify-center align-center mb-4"
  >
    <SwitchViewForAdmin />
    <AdminTable
      :users="teachers"
      :isAdmin="true"
      :classes="classes"
      @edited-teacher="fetchTeachers"
      :isTherapyAdmin="isTherapyOrParentAdmin"
    />
    <p
      v-if="isSchoolClient"
      class="text-h6 text-sm-h5 text-md-h5 primary--text text-center"
    >
      {{ $t('manageClass.title') }}
    </p>
    <v-col
      v-if="isSchoolClient"
      cols="10"
      sm="10"
      md="10"
      lg="8"
      xl="8"
      class="mt-4 mb-12 manage-classes-box rounded"
    >
      <ManageClasses
        class="my-8"
        :schoolId="schoolId"
        :classLimit="classLimit"
        @on-update="fetchBasicInfo"
      />
    </v-col>
    <TeacherDashboardTherapistAddAccount
      v-if="isTherapyOrParentAdmin"
      :items="classes"
      @addedTeacher="fetchTeachers"
    />
    <TeacherDashboardAddAccount v-else @addedTeacher="fetchTeachers" />
    <TeachersTable
      :users="teachers"
      :classes="classes"
      @edited-teacher="fetchTeachers"
      :isTherapyAdmin="isTherapyOrParentAdmin"
    />
    <AdminSnackbar />
  </v-col>
</template>

<script lang="ts">
import SwitchViewForAdmin from './table/buttons/SwitchViewForAdmin.vue';
import AdminTable from './table/AdminTable.vue';
import router from '@/router';
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import { Client, UserDto } from '../../../api/types/api';
import AdminButton from '../base/AdminButton.vue';
import AdminSnackbar from '../base/AdminSnackbar.vue';
import TeachersTable from './table/TeachersTable.vue';
import TeacherDashboardAddAccount from './TeacherDashboardAddAccount.vue';
import TeacherDashboardTherapistAddAccount from './TeacherDashboardTherapistAddAccount.vue';
import { getSchoolPrefix } from '@/utils/common';
import SnackbarModule from '@/store/modules/SnackbarModule';
import ManageClasses from '../../superadmin/school-classes/Manage-Classes.vue';

@Component({
  components: {
    TeacherDashboardAddAccount,
    TeachersTable,
    AdminSnackbar,
    TeacherDashboardTherapistAddAccount,
    AdminButton,
    AdminTable,
    SwitchViewForAdmin,
    ManageClasses,
  },
})
export default class TeacherDashboard extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);

  teachers: UserDto[] = [];

  get schoolId(): string {
    return this.schoolModule.schoolId ?? '';
  }

  get classes(): string[] {
    return this.schoolModule.classes ?? [];
  }

  get classLimit(): number {
    return this.schoolModule.classLimit;
  }

  get isSchoolClient(): boolean {
    return this.schoolModule.isSchoolClient;
  }

  get isTherapyOrParentAdmin(): boolean {
    return (
      this.schoolModule.clientType === Client.therapy ||
      this.schoolModule.clientType === Client.parent
    );
  }

  async created(): Promise<void> {
    await this.fetchTeachers();
  }

  async fetchTeachers(): Promise<void> {
    try {
      this.teachers = await this.schoolModule.getTeachersOrTherapists();
    } catch (error) {
      this.snackbarModule.showMessage({
        message: this.$i18n.t('client.snackbar.fetch.error').toString(),
        isSuccess: false,
      });
    }
  }

  async fetchBasicInfo(): Promise<void> {
    await this.schoolModule.getBasicSchoolInfo(this.schoolModule.slug);
    await this.fetchTeachers();
  }

  goToStudentsTeachers(): void {
    router.push({
      name: 'students',
      params: { schoolName: getSchoolPrefix() },
    });
  }
}
</script>

<style scoped lang="scss">
.manage-classes-box {
  background-color: white;
}
</style>
