<template>
  <v-col cols="12" class="align-self-start mb-6" align="center">
    <v-col cols="10" md="10" lg="8">
      <v-form @submit.prevent="validate">
        <p class="text-h6 text-sm-h5 text-md-h5 primary--text">
          {{ $t('admin.account.create') }}
        </p>
        <v-col cols="12" class="ma-0 pa-0 grey--text text--darken-3">
          <p v-html="$t('adminTable.existingTeacher')"></p>
        </v-col>
        <v-row class="add-teacher-form justify-space-between my-2">
          <v-col class="ma-0 pa-0" cols="12">
            <v-select
              v-model="teacherClasses"
              :items="classNames"
              label="Klasse*"
              :error-messages="selectErrors"
              dense
              solo
              background-color="white"
              class="addTeacher elevation-n1 px-1"
              id="grade"
              chips
              multiple
            >
            </v-select
          ></v-col>
          <!-- <v-col cols="10" sm="10" md="3" lg="3" xl="3"> -->
          <v-text-field
            v-model="firstname"
            :error-messages="firstnameErrors"
            label="Vorname*"
            id="firstname"
            solo
            dense
            required
            background-color="white"
            class="addTeacher px-1"
          ></v-text-field>
          <!-- </v-col> -->

          <!-- <v-col cols="10" sm="10" md="3" lg="3" xl="3"> -->
          <v-text-field
            v-model="lastname"
            :error-messages="lastnameErrors"
            label="Nachname*"
            id="lastname"
            solo
            dense
            required
            background-color="white"
            class="addTeacher px-1"
          ></v-text-field>
          <!-- </v-col> -->

          <!-- <v-col cols="10" sm="10" md="3" lg="3" xl="3"> -->
          <v-text-field
            v-model="email"
            :error-messages="emailErrors"
            label="E-mail*"
            id="email"
            solo
            dense
            required
            background-color="white"
            class="addTeacher px-1"
          ></v-text-field>
          <!-- </v-col> -->
        </v-row>

        <v-btn
          id="create-teacher"
          color="primary"
          class="mr-4"
          :loading="loading"
          type="submit"
        >
          <v-icon left dark>mdi-account-plus </v-icon>
          <p class="ma-0 mt-1 pa-0">{{ $t('admin.create-account') }}</p>
        </v-btn>
      </v-form>
    </v-col>
  </v-col>
</template>

<script lang="ts">
import SchoolModule from '@/store/modules/SchoolModule';
import UserModule from '@/store/modules/UserModule';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { validationMixin } from 'vuelidate';
import { email, minLength, required } from 'vuelidate/lib/validators';
import { getModule } from 'vuex-module-decorators';
import { Role } from '../../../api/types';
import SnackbarModule from '../../../store/modules/SnackbarModule';
import { DEFAULT_PASSWORD } from '../../../utils/common';

@Component({
  mixins: [validationMixin],
  validations: {
    firstname: { required, minLength: minLength(3) },
    lastname: { required, minLength: minLength(3) },
    email: { required, email },
    teacherClasses: { required },
  },
  components: {},
})
export default class TeacherDashboardAddAccount extends Vue {
  schoolModule: SchoolModule = getModule(SchoolModule, this.$store);
  userModule: UserModule = getModule(UserModule, this.$store);
  snackbarModule: SnackbarModule = getModule(SnackbarModule, this.$store);

  @Prop() isTherapyAdmin!: boolean;

  firstname = '';
  lastname = '';
  cEmail = '';
  teacherClasses: string[] = [];
  loading = false;

  get email(): string {
    return this.cEmail.trim();
  }

  set email(value: string) {
    this.cEmail = value;
  }

  get schoolId(): string {
    return this.schoolModule.schoolId!.toString();
  }

  get classNames(): string[] {
    return this.schoolModule.classes;
  }

  async validate(): Promise<void> {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.loading = true;
      try {
        const chosenClasses = this.teacherClasses.join(', ').split(', ');
        const allClasses = this.schoolModule.school.classes;

        await this.schoolModule.addUser({
          username: this.email,
          firstname: this.firstname,
          lastname: this.lastname,
          classes: allClasses!.filter((c) => chosenClasses.includes(c.name)),
          email: this.email,
          schoolId: this.schoolId,
          role: Role.teacher,
          password: DEFAULT_PASSWORD,
        });
        this.snackbarModule.showMessage({
          message: this.isTherapyAdmin
            ? this.$i18n.t('snackbar.therapists.created.success').toString()
            : this.$i18n.t('snackbar.teachers.created.success').toString(),
          isSuccess: true,
        });
        this.$emit('addedTeacher');
        this.clear();
      } catch (error: any) {
        if (error?.response.status === 409) {
          this.snackbarModule.showMessage({
            message: this.isTherapyAdmin
              ? this.$i18n.t('snackbar.therapists.created.errorMail').toString()
              : this.$i18n.t('snackbar.teachers.created.errorMail').toString(),
            isSuccess: false,
          });
        } else {
          this.snackbarModule.showMessage({
            message: this.isTherapyAdmin
              ? this.$i18n.t('snackbar.therapists.created.error').toString()
              : this.$i18n.t('snackbar.teachers.created.error').toString(),
            isSuccess: false,
          });
        }
      } finally {
        this.loading = false;
      }
    }
  }

  clear(): void {
    this.firstname = '';
    this.lastname = '';
    this.cEmail = '';
    this.teacherClasses = [];
    this.$v.$reset();
  }

  get selectErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.teacherClasses.$dirty) return errors;
    !this.$v.teacherClasses.required && errors.push('Klasse auswählen.');
    return errors;
  }

  get firstnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.firstname.$dirty) return errors;
    !this.$v.firstname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.firstname.required && errors.push('Vornamen angeben.');
    return errors;
  }
  get lastnameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.lastname.$dirty) return errors;
    !this.$v.lastname.minLength && errors.push('Mind. 3 Zeichen');
    !this.$v.lastname.required && errors.push('Nachnamen angeben.');
    return errors;
  }

  get emailErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.email.$dirty) return errors;
    !this.$v.email.email && errors.push('Keine gültige E-Mailadresse.');
    !this.$v.email.required && errors.push('E-Mail angeben.');
    return errors;
  }
}
</script>

<style scoped lang="scss">
// >>> .v-input__control > .v-input__slot {
//   box-shadow: inset 0 2px 1px -1px rgba(0, 0, 0, 0.2),
//     inset 0 1px 1px 0 rgba(0, 0, 0, 0.14), inset 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
// }
</style>

<style lang="scss">
.addTeacher.error--text,
.addTeacher.error--text .v-text-field__slot input,
.addTeacher.error--text .v-label.error--text,
.addTeacher .error--text .v-messages__message {
  color: $lesikus-error-color;
  caret-color: $lesikus-error-color;
}

.addTeacher.v-text-field--solo .v-input__slot {
  box-shadow: inset 0 2px 1px -1px rgba(0, 0, 0, 0.2),
    inset 0 1px 1px 0 rgba(0, 0, 0, 0.14), inset 0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
}
</style>
